import { useState } from 'react';
import { CAREER_COMPASS_CONSTANTS } from '../../services/career-compass.service';

const useLearningModule = (units) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const shouldShowUnits = units?.length > 0;
  const getNextIncompleteUnit = () => {
    if (!units || units.length === 0) return null;
    return units.find((unit) => unit.progress !== CAREER_COMPASS_CONSTANTS.UNIT_PROGRESS.COMPLETED);
  };

  const getNextIncompleteUnitPropertyOrDefault = (propertyName, defaultValue) => {
    const firstIncompleteUnit = getNextIncompleteUnit();
    return firstIncompleteUnit ? firstIncompleteUnit[propertyName] : defaultValue;
  };

  const getNextIncompleteUnitIdOrDefault = (defaultId) => getNextIncompleteUnitPropertyOrDefault('id', defaultId);

  const getNextIncompleteUnitNameOrDefault = (defaultName) => getNextIncompleteUnitPropertyOrDefault('name', defaultName);

  return {
    expanded,
    handleAccordionChange,
    shouldShowUnits,
    getNextIncompleteUnit,
    getNextIncompleteUnitIdOrDefault,
    getNextIncompleteUnitNameOrDefault,
  };
};

export default useLearningModule;
