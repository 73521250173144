export default (theme) => ({
  container: {
    width: '611px',
    height: 'auto',
    border: '1px solid #D8DCE0',
    borderRadius: '24px',
    color: '#FFFFFF',
  },
  title: {
    font: 'Inter',
    fontWeight: 800,
    fontSize: '30px',
    lineHeight: '36.31px',
    color: theme.palette.navy.main,
    padding: '24px',
    textTransform: 'none',
  },
  bodyText: {
    font: 'Inter',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '24px',
    color: theme.palette.greyScale[110],
    height: '204px',
    width: '563px',
    display: 'flex',
    marginTop: '-26px',
  },
  recordText: {
    font: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '24px',
    color: theme.palette.greyScale[110],
    height: '204px',
    width: '563px',
    display: 'flex',
    marginTop: '-80px',
  },
  speakFreeText: {
    font: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '24px',
    color: theme.palette.greyScale[110],
    height: '204px',
    display: 'flex',
    marginTop: '-180px',
  },
  stepText: {
    font: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '24px',
    color: theme.palette.greyScale[110],
    height: '204px',
    width: '563px',
    display: 'flex',
    marginTop: '-160px',
  },
  overlineText: {
    font: 'Rubik',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '24px',
    color: theme.palette.cerulean.cerulean20,
    height: '204px',
    width: '563px',
    display: 'flex',
    marginTop: '-160px',
  },
  quietLocationText: {
    font: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '24px',
    color: theme.palette.greyScale[110],
    height: '204px',
    width: '563px',
    display: 'flex',
    marginTop: '-170px',
  },
  holdTabletText: {
    font: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '24px',
    color: theme.palette.greyScale[110],
    width: '563px',
    display: 'flex',
    marginTop: '-170px',
  },
  icon: {
    color: theme.palette.blue.primary,
    marginRight: '4px',
    height: '20px',
    width: '18px',
  },
  NoiceCancellationIcon: {
    marginTop: '-4px',
    color: theme.palette.blue.primary,
    marginRight: '4px',
    height: '20px',
    width: '18px',
  },

  recordingContainer: {
    width: '611px',
    height: 'auto',
    border: '1px solid #D8DCE0',
    borderRadius: '24px',
    color: '#FFFFFF',
    marginTop: '24px',
  },

  recordingContainerSubTitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    padding: '24px',
    color: theme.palette.greyScale[110],
    width: '563px',
    display: 'flex',
    marginTop: '-32px',
  },
  recordingContainerBody: {
    fontWeight: '500',
    font: 'Rubik',
    fontSize: '14px',
    lineHeight: '16.59px',
    color: theme.palette.cerulean.cerulean20,
    display: 'flex',
    padding: '24px',
    marginBottom: '-26px',
  },
  promptsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  promptText: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.navy.main,
    padding: '24px',
    width: '82%',
  },
  promptsIconsBox: {
    marginTop: '6px',
    display: 'flex',
  },
  promptsIconButton: {
    marginRight: '-24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  keyboardLeftIcon: {
    color: '#007E87',
    border: '1px solid #007E87',
    borderRadius: '100px',
    width: '35px',
    height: '35px',
  },
  keyboardRightIcon: {
    color: '#007E87',
    border: '1px solid #007E87',
    borderRadius: '100px',
    width: '35px',
    height: '35px',
    marginLeft: '6px',
  },
  voiceAuthenticationBanner: {
    marginBottom: '10px',
  },
});
