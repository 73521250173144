import { useEffect, useState } from 'react';
import { executeUnlessBackdropOrEscape } from '../../../../shared/services/utility/utility';
import { ENTERTAINMENT_CENTER_CONSTANTS, fetchVideoAdvertisement } from '../../services/entertainment-center.service';

const { POST_MESSAGE_EVENT_TYPES } = ENTERTAINMENT_CENTER_CONSTANTS;

/**
 * Custom hook to handle the video modal player
 *
 * @returns {Object} - Object containing the following properties:
 * open - boolean - state to show/hide the modal
 * showPlayer - boolean - state to show/hide the player
 * movieDetails - object - object containing the movie details
 * handleClose - function - function to close the modal
 * showLanguageList - function - function to show the language list
 * handleLanguageChange - function - function to handle language change
 * isLanguageActive - function - function to check if the language is active
 */
const useVideoModalPlayerHook = () => {
  const [open, setOpen] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [movieDetails, setMovieDetails] = useState({});
  const [activeLanguageIndex, setActiveLanguageIndex] = useState(0);
  const [adUrl, setAdUrl] = useState(null);
  /**
   * Effect to handle the post message event
   */
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === POST_MESSAGE_EVENT_TYPES.PLAY_MOVIE) {
        const { baseUrl, streamUrl, movieName, languages, thumbnail } = event.data.value;
        setMovieDetails({ baseUrl, movieUrl: `${baseUrl}${streamUrl}`, movieName, languages, thumbnail });
        setActiveLanguageIndex(0);
        setShowPlayer(true);
        setOpen(true);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const loadAdvertisement = async () => {
      try {
        const ad = await fetchVideoAdvertisement();
        setAdUrl(ad.url);
      } catch (err) {
        console.log('Error fetching video advertisement:', err);
      }
    };

    loadAdvertisement();
  }, []);

  /**
   * Function to handle the close event
   *
   * @param {object} e - event object
   * @param {string} reason - reason for closing the modal
   */
  const handleClose = (e, reason) => {
    executeUnlessBackdropOrEscape(reason, () => {
      setOpen(false);
      setShowPlayer(false);
    });
  };

  /**
   * Function to show the language list
   *
   * @returns {boolean} - boolean value to show/hide the language list
   */
  const showLanguageList = () => movieDetails.languages?.length > 0;

  /**
   * Function to handle the language change
   *
   * @param {number} index - index of the language
   * @returns {void}
   */
  const handleLanguageChange = (index) => {
    if (index === activeLanguageIndex) return;

    setShowPlayer(false);
    setTimeout(() => {
      const language = movieDetails.languages[index];
      setMovieDetails((prev) => ({ ...prev, movieUrl: `${prev.baseUrl}${language.streamUrl}` }));
      setActiveLanguageIndex(index);
      setShowPlayer(true);
    }, 100);
  };

  /**
   * Function to check if the language is active
   *
   * @param {number} index - index of the language
   * @returns {boolean} - boolean value to check if the language is active
   */
  const isLanguageActive = (index) => index === activeLanguageIndex;

  return {
    open,
    showPlayer,
    movieDetails,
    adUrl,
    handleClose,
    showLanguageList,
    handleLanguageChange,
    isLanguageActive,
  };
};

export default useVideoModalPlayerHook;
