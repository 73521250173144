import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  IconButton,
} from '@material-ui/core';
import styles from './learning-module.styles';
import { FormattedMessage } from 'react-intl';
import { PillButton } from '../../../../../frontend-commons';
import LaunchIcon from '@material-ui/icons/Launch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import useLearningModuleHook from '../../../programs/components/learning-module/useLearningModuleHook';
import useLearningModule from './useLearningModule';
import { Constant } from '../../../../../shared';
import ModuleUnit from './module-unit';

/**
 * Component that renders the Learning Module
 *
 * @component
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const LearningModule = ({ classes, disabled, moduleId, title, contentProgress, units, isInProgress }) => {
  const { launchModule } = useLearningModuleHook({});
  const {
    expanded,
    handleAccordionChange,
    shouldShowUnits,
    getNextIncompleteUnitIdOrDefault,
    getNextIncompleteUnitNameOrDefault,
  } = useLearningModule(units);

  return (
    <Accordion
      expanded={expanded}
      onChange={shouldShowUnits ? handleAccordionChange : null}
      className={[classes.root, disabled ? classes.disabledModule : classes.activeModule].join(' ')}
    >
      <AccordionSummary
        expandIcon={shouldShowUnits ? <ExpandMoreIcon /> : null}
        IconButtonProps={{ edge: 'start' }}
        aria-controls={`module-${moduleId}-content`}
        id={`module-${moduleId}-header`}
      >
        <Box className={[classes.textArea, !shouldShowUnits ? classes.noUnits : ''].join(' ')}>
          <Typography data-testid="learning-module-title" className={classes.title}>
            {title}
          </Typography>
          {contentProgress && contentProgress.total > 0 && (
            <Typography data-testid="learning-module-subtitle" variant="body2" className={classes.subtitle}>
              <FormattedMessage
                id={units ? 'unitsCompleted' : 'topicsCompleted'}
                values={{ completed: contentProgress.completed ?? 0, total: contentProgress.total ?? 0 }}
              />
            </Typography>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          <IconButton
            className={classes.circularButton}
            aria-label="Module details"
            tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
            tracking-id={`module-details-${moduleId}`}
            onClick={(event) => {
              event.stopPropagation();
              launchModule(moduleId, title);
            }}
          >
            <LibraryBooksIcon />
          </IconButton>
          {!disabled && (
            <PillButton
              data-testid="launch-button"
              color="primary"
              variant="contained"
              className={classes.launchButton}
              onClick={(event) => {
                event.stopPropagation();
                launchModule(getNextIncompleteUnitIdOrDefault(moduleId), getNextIncompleteUnitNameOrDefault(title));
              }}
              endIcon={<LaunchIcon />}
              aria-label="Launch"
              tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
              tracking-id={`launch-program-unit-${moduleId}`}
            >
              <FormattedMessage
                id={
                  isInProgress
                    ? 'career-compass.program-details.launchButton.continue'
                    : 'career-compass.program-details.launchButton.start'
                }
              />
            </PillButton>
          )}
        </Box>
      </AccordionSummary>
      {expanded && <Divider />}
      {shouldShowUnits && (
        <AccordionDetails className={classes.unitsContainer}>
          {units?.map((unit, index) => (
            <ModuleUnit
              key={unit.id}
              unit={unit}
              isLast={index === units.length - 1}
              launchModule={() => launchModule(unit.id, unit.name)}
            />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

LearningModule.defaultProps = {
  disabled: false,
  units: [],
  isInProgress: false,
};

LearningModule.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The prop that determines if the module is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The id of the module.
   */
  moduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * The title of the module.
   */
  title: PropTypes.string.isRequired,
  /**
   * The topics of the module.
   */
  contentProgress: PropTypes.shape({
    completed: PropTypes.number,
    total: PropTypes.number,
  }),
  /**
   * The units of the module.
   */
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      displayOrder: PropTypes.number,
      progress: PropTypes.string.isRequired,
      contentProgress: PropTypes.shape({
        total: PropTypes.number.isRequired,
        completed: PropTypes.number.isRequired,
      }).isRequired,
      isInProgress: PropTypes.bool,
    })
  ),
};

export default withStyles(styles)(LearningModule);
