import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, Typography, withStyles } from '@material-ui/core';
import styles from './program-content.styles';
import useProgramContentHook from './useProgramContentHook';
import { FormattedMessage, useIntl } from 'react-intl';
import LearningModule from '../learning-module/learning-module';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

/**
 * Component that renders the Program Content i.e units
 *
 * @component
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const ProgramContent = ({ classes, ...props }) => {
  const {
    inProgressItems,
    showAllItems,
    hasInProgressItems,
    shouldShowViewMore,
    getNotStartedItemsSlice,
    toggleShowAllItems,
  } = useProgramContentHook(props);
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Typography data-testid="program-content-section-title-1" className={classes.sectionTitle}>
        <FormattedMessage id="career-compass.program-details.current-course" />
      </Typography>

      {props.isSingleCourseProgram ? (
        <LearningModule
          moduleId={props.program?.rootLearningObject?.id}
          title={props.program?.name}
          contentProgress={props.program?.rootLearningObject?.contentProgress}
          units={props.program?.learningObjects}
          isInProgress={hasInProgressItems()}
        />
      ) : (
        <>
          {inProgressItems.map((item) => (
            <LearningModule
              key={item.id}
              moduleId={item.id}
              title={item.name}
              contentProgress={item.contentProgress}
              units={item.nestedLearningObjects}
              isInProgress={hasInProgressItems()}
            />
          ))}

          <Typography data-testid="program-content-section-title-2" className={classes.sectionTitle}>
            <FormattedMessage id="upNext" />
            ...
          </Typography>

          {getNotStartedItemsSlice().map((item) => (
            <LearningModule
              key={item.id}
              moduleId={item.id}
              title={item.name}
              contentProgress={item.contentProgress}
              units={item.nestedLearningObjects}
              isInProgress={hasInProgressItems()}
              disabled
            />
          ))}

          <Collapse in={showAllItems} classes={{ wrapperInner: classes.modulesContainer }}>
            {getNotStartedItemsSlice(false).map((item) => (
              <LearningModule
                key={item.id}
                moduleId={item.id}
                title={item.name}
                contentProgress={item.contentProgress}
                units={item.nestedLearningObjects}
                isInProgress={hasInProgressItems()}
                disabled
              />
            ))}
          </Collapse>

          {shouldShowViewMore() && (
            <Box className={classes.viewMoreContainer}>
              <Button
                data-testid="view-more-button"
                color="primary"
                className={classes.viewMoreButton}
                onClick={toggleShowAllItems}
                endIcon={showAllItems ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                aria-label={intl.formatMessage({ id: showAllItems ? 'viewLess' : 'viewMore' })}
              >
                <FormattedMessage id={showAllItems ? 'viewLess' : 'viewMore'} />
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

ProgramContent.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The content to display.
   */
  content: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProgramContent);
