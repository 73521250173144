import React from 'react';
import { Box, Icon, Typography, IconButton, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import VoiceAuthenticationBanner from '../voice-authentication-banner/voice-authentication-banner';
import { Constant, NoiceCancellationIcon } from '../../../../shared';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useVoiceIdRegistrationHook } from './useVoiceIdRegistrationHook';
import { withStyles } from '@material-ui/core/styles';
import styles from './voice-id-registration.style';
import VoiceIdSubmitted from './components/voice-id-submitted/voice-id-submitted';
import VoiceRecording from './components/voice-recording/voice-recording';

const VoiceIdRegistration = ({ classes, handletabActive, TAB_VALUES }) => {
  const { activePrompt, handleNextPrompt, handlePreviousPrompt, PROMPTS, profileStatus, loading, setProfileStatus } =
    useVoiceIdRegistrationHook();
  const renderVoiceAuthenticationBanner = () => {
    return <VoiceAuthenticationBanner showCreateButton={false} handletabActive={handletabActive} TAB_VALUES={TAB_VALUES} />;
  };
  return (
    <>
      {profileStatus && profileStatus !== Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED && (
        <Grid container sm={12} spacing={3} className={classes.voiceAuthenticationBanner}>
          <Grid item sm={12}>
            {renderVoiceAuthenticationBanner()}
          </Grid>
        </Grid>
      )}
      {loading ? (
        <>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="100%" height={80} />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="75%" />
          <Skeleton variant="text" width="65%" />
          <Skeleton variant="rectangular" width="100%" height={200} />
        </>
      ) : profileStatus === Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED ? (
        <VoiceIdSubmitted handletabActive={handletabActive} TAB_VALUES={TAB_VALUES} />
      ) : (
        <>
          <Box className={classes.container} data-testid="voice-id-container">
            <Typography variant="h2" className={classes.title} data-testid="title">
              Recording Directions
            </Typography>

            <Typography variant="body" className={classes.bodyText} data-testid="body-text">
              Use of this calling feature requires that you enroll in Voice ID. Voice ID will be used to identify your voice
              on all non-privileged calls. If you do not agree to this use, do not enroll in Voice ID or use this calling
              feature.
            </Typography>

            <Typography variant="body" className={classes.recordText} data-testid="record-text">
              1. Tap the Record button to start.
            </Typography>

            <Typography variant="body" className={classes.speakFreeText} data-testid="postion-your-face-text">
              2. Position your face in the center of the video preview.
            </Typography>
            <Typography variant="body" className={classes.speakFreeText} data-testid="speak-free-text-1">
              3. Speak freely for at least 30 seconds or follow the provided prompts.
            </Typography>

            <Typography variant="body" className={classes.speakFreeText} data-testid="speak-free-text-2">
              4. Press Submit once you’re satisfied with your recording.
            </Typography>

            <Typography variant="body" className={classes.speakFreeText} data-testid="speak-free-text-3">
              5. Wait for the system to process before making calls.
            </Typography>

            <Typography variant="overline" className={classes.overlineText} data-testid="overline-text">
              Voice Recording Tips
            </Typography>

            <Typography variant="body" className={classes.stepText} data-testid="step-text">
              <VolumeUpIcon className={classes.icon} />
              Speak loudly and clearly
            </Typography>

            <Typography variant="body" className={classes.quietLocationText} data-testid="quiet-location-text">
              <Icon className={classes.NoiceCancellationIcon}>
                <NoiceCancellationIcon />
              </Icon>
              Move to a quiet location if possible
            </Typography>

            <Typography variant="body" className={classes.quietLocationText} data-testid="enunciate-text">
              <RecordVoiceOverIcon className={classes.icon} />
              Say your words slowly and carefully
            </Typography>

            <Typography variant="body" className={classes.holdTabletText} data-testid="hold-tablet-text">
              <TabletMacIcon className={classes.icon} />
              Hold the tablet closer
            </Typography>
          </Box>

          <Box className={classes.recordingContainer} data-testid="recording-container">
            <Typography variant="h2" className={classes.title} data-testid="recording-title">
              Tap to Record and Speak
            </Typography>

            <Typography variant="body" className={classes.recordingContainerSubTitle} data-testid="recording-subtitle">
              You can speak freely or use the prompts below to help you create a voice sample. Please speak for at least 30
              seconds non-stop.
            </Typography>

            <Typography variant="overline" className={classes.recordingContainerBody} data-testid="recording-body">
              Not sure what to say?
            </Typography>

            <Box className={classes.promptsContainer} data-testid="prompts-container">
              <Typography variant="body" className={classes.promptText} data-testid="prompt-text">
                {PROMPTS[activePrompt]}
              </Typography>

              <Box className={classes.promptsIconsBox} data-testid="prompts-icons-box">
                <IconButton className={classes.promptsIconButton} onClick={handleNextPrompt}>
                  <KeyboardArrowLeftIcon className={classes.keyboardLeftIcon} data-testid="next-prompt-icon" />
                </IconButton>
                <IconButton className={classes.promptsIconButton} onClick={handlePreviousPrompt}>
                  <KeyboardArrowRightIcon className={classes.keyboardRightIcon} data-testid="previous-prompt-icon" />
                </IconButton>
              </Box>
            </Box>
            <VoiceRecording setProfileStatus={setProfileStatus} />
          </Box>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(VoiceIdRegistration);
