export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  voiceRecordingVector: {
    width: '70%',
    height: '70%',
    marginTop: '32px',
  },
  voiceRecordingTimerContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  voiceRecordingTime: {
    fontStyle: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: theme.palette.orange.deep,
  },
  voiceRecordingTimeDone: {
    fontStyle: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: '#228053',
  },

  isRecordingInProgressText: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.greyScale[110],
    marginTop: '16px',
    marginBottom: '10px',
    width: '70%',
  },
  voiceRecordingTimeDivider: {
    fontStyle: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: '#4F5154',
    marginRight: '4px',
    marginLeft: '4px',
  },
  voiceRecordingTimeTotal: {
    fontStyle: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: theme.palette.blue.primary,
  },
  startRecordingButton: {
    width: '92px',
    height: '92px',
    top: '18px',
    marginBottom: '50px',
    marginLeft: '50px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
    },
  },
  startRecordingIcon: {
    width: '32px',
    height: '32px',
  },
  refreshButton: {
    width: '44px',
    height: '44px',
    backgroundColor: theme.palette.orange.deep,
    left: '125px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.greyScale[30],
    },
    '&:hover': {
      backgroundColor: theme.palette.orange.deep,
    },
  },
  refreshIcon: {
    width: '24px',
    height: '24px',
  },
  refreshIconWhite: {
    width: '24px',
    height: '24px',
    color: '#FFFFFF',
  },
  recordingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  voiceRecordingCountDown: {
    fontWeight: '800',
    fontSize: '32px',
    lineHeight: '38.73px',
    color: '#FFFFFF',
  },
  recordingStarted: {
    backgroundColor: theme.palette.orange.deep,
    width: '92px',
    height: '92px',
    top: '18px',
    marginBottom: '50px',
    marginLeft: '50px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.orange.deep,
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: theme.palette.orange.deep,
    },
  },
  stopRecordingIcon: {
    width: '32px',
    height: '32px',
    color: theme.palette.orange.deep,
    borderRadius: '50%',
    border: '10px solid #FFFFFF',
  },
  sendIcon: {
    width: '32px',
    height: '32px',
    color: '#FFFFFF',
  },
  tapToRecord: {
    color: theme.palette.navy.main,
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19.36px',
    marginBottom: '20px',
  },
  hourglassIcon: {
    width: '32px',
    height: '32px',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
    },
  },
  refreshText: {
    color: theme.palette.navy.main,
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '19.36px',
    marginBottom: '20px',
    marginLeft: '390px',
    marginTop: '-45px',
  },
  videoTag: {
    width: '80%',
    marginTop: '12px',
  },
});
