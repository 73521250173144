import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CAREER_COMPASS_CONSTANTS } from '../../services/career-compass.service';
import useCareerCompassHook from './useCareerCompassHook';
import { connect } from 'react-redux';
import { Permission } from '../../../../../core';
import { Box, withStyles } from '@material-ui/core';
import styles from './career-compass.styles';
import { Loader } from '@orijinworks/frontend-commons';

const withCareerCompassAuth = (WrappedComponent, allowedPage) => {
  function WithCareerCompassAuth({ classes, history, match, currentStep, selectedLocale, ...props }) {
    const { programId, showIntroductionGuide, showProgramSelection, showProgramDetails, handleCurrentStepUpdate } =
      useCareerCompassHook(currentStep);

    const urlProgramId = match?.params?.id;

    /**
     * Function to check if the user is allowed to access the page.
     */
    const isAllowedPage = useMemo(() => {
      switch (allowedPage) {
        case CAREER_COMPASS_CONSTANTS.PAGES.INTRODUCTION_GUIDE:
          return showIntroductionGuide();
        case CAREER_COMPASS_CONSTANTS.PAGES.PROGRAM_SELECTION:
          return showProgramSelection();
        case CAREER_COMPASS_CONSTANTS.PAGES.PROGRAM_DETAILS:
          return showProgramDetails();
        default:
          return false;
      }
    }, [showIntroductionGuide, showProgramSelection, showProgramDetails]);

    /**
     * Function to redirect to the specified page if the condition is met.
     *
     * @param {function} shouldRedirect - The function to check if the redirection is needed
     * @param {string} redirectPath - The redirect path
     * @returns {void}
     */
    const redirectToPage = useCallback(
      (shouldRedirect, redirectPath) => {
        if (shouldRedirect()) {
          history.push(redirectPath);
        }
      },
      [history]
    );

    useEffect(() => {
      if (!isAllowedPage) {
        /**
         * Redirect if user is on the Introduction Guide step but tries to access another page.
         */
        redirectToPage(showIntroductionGuide, '/career-compass/introduction');

        /**
         * Redirect if user is on the Program Selection step but tries to access another page.
         */
        redirectToPage(showProgramSelection, '/career-compass/program-selection');

        /**
         * Redirect if user is on the Program Details step but tries to access another page.
         */
        redirectToPage(showProgramDetails, `/career-compass/program-details/${programId}`);
      } else if (allowedPage === CAREER_COMPASS_CONSTANTS.PAGES.PROGRAM_DETAILS && urlProgramId !== programId) {
        /**
         * Update the URL if urlProgramId does not match the programId.
         */
        history.replace(`/career-compass/program-details/${programId}`);
      }
    }, [
      isAllowedPage,
      programId,
      redirectToPage,
      showIntroductionGuide,
      showProgramDetails,
      showProgramSelection,
      history,
      urlProgramId,
      handleCurrentStepUpdate,
    ]);

    /**
     * Render the loader if the user is not allowed to access the page.
     * This is to prevent the user from seeing the page for a split second before being redirected.
     */
    if (!isAllowedPage) {
      return <Loader isLoading />;
    }

    /**
     * Render the appropriate component based on the current step and the allowed page.
     */
    return (
      <Permission {...props}>
        <Box className={classes.root}>
          {showIntroductionGuide() && <WrappedComponent history={history} onContinue={handleCurrentStepUpdate} />}
          {showProgramSelection() && (
            <WrappedComponent 
              currentStep={currentStep}
              selectedLocale={selectedLocale}
              handleCurrentStepUpdate={handleCurrentStepUpdate}
            />
          )}
          {showProgramDetails() && (
            <WrappedComponent
              programId={programId}
              currentStep={currentStep}
              selectedLocale={selectedLocale}
              handleCurrentStepUpdate={handleCurrentStepUpdate}
            />
          )}
        </Box>
      </Permission>
    );
  }

  WithCareerCompassAuth.propTypes = {
    /**
     * The classes object to use for styling that will be injected by withStyles.
     */
    classes: PropTypes.object.isRequired,
    /**
     * The history object to use for navigation.
     */
    history: PropTypes.object.isRequired,
    /**
     * The current step object.
     */
    currentStep: PropTypes.object,
    /**
     * The selected locale.
     */
    selectedLocale: PropTypes.string.isRequired,
  };

  const mapStateToProps = ({ locale, careerCompass }) => {
    return {
      currentStep: careerCompass.currentStep,
      selectedLocale: locale.locale,
    };
  };

  return connect(mapStateToProps)(withStyles(styles)(WithCareerCompassAuth));
};

withCareerCompassAuth.propTypes = {
  /**
   * The wrapped component to render
   */
  WrappedComponent: PropTypes.elementType.isRequired,
  /**
   * The allowed page
   */
  allowedPage: PropTypes.string.isRequired,
};

export default withCareerCompassAuth;
