import { Http } from '../../../../core';

/**
 * Constants for entertainment center service.
 */
export const ENTERTAINMENT_CENTER_CONSTANTS = {
  /**
   * The event types for window.postMessage.
   */
  POST_MESSAGE_EVENT_TYPES: {
    USE_PARENT_WINDOW_PLAYER: 'USE_PARENT_WINDOW_PLAYER',
    PLAY_MOVIE: 'PLAY_MOVIE',
  },
};

/**
 * Function to prepare the video advertisement data
 *
 * @param {Object} advertisementData - The advertisement data
 * @returns {Object|null} - The prepared advertisement data
 */
export const prepareVideoAdvertisement = (advertisementData) => {
  if (!advertisementData?.url) return null;

  return {
    id: advertisementData.id,
    url: advertisementData.url,
  };
};

/**
 * Fetches video advertisement from the API
 * @returns {Promise<Object|null>} The video advertisement data
 */
export const fetchVideoAdvertisement = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get('/services/advertisements/v1')
      .then((response) => {
        if (response?.data?.payload) {
          const ad = prepareVideoAdvertisement(response.data.payload[0]);
          resolve(ad);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
