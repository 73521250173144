import { IntroductionGuide, ProgramSelection, ProgramDetails } from '../components';
import withCareerCompassAuth from './career-compass/withCareerCompassAuth';
import { CAREER_COMPASS_CONSTANTS } from '../services/career-compass.service';

export { default as CareerCompass } from './career-compass/career-compass';

export const CareerCompassIntroduction = withCareerCompassAuth(
  IntroductionGuide,
  CAREER_COMPASS_CONSTANTS.PAGES.INTRODUCTION_GUIDE
);
export const CareerCompassProgramSelection = withCareerCompassAuth(
  ProgramSelection,
  CAREER_COMPASS_CONSTANTS.PAGES.PROGRAM_SELECTION
);
export const CareerCompassProgramDetails = withCareerCompassAuth(
  ProgramDetails,
  CAREER_COMPASS_CONSTANTS.PAGES.PROGRAM_DETAILS
);
