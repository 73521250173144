import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, withStyles, IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PendingIcon from '@mui/icons-material/Pending';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import styles from './module-unit.styles';
import useModuleUnit from './useModuleUnit';
import { Constant } from '../../../../../shared';

const ModuleUnit = ({ classes, unit, isLast, launchModule }) => {
  const { isCompleted, isInProgress, isNotStarted } = useModuleUnit(unit);

  return (
    <>
      <Box className={classes.unitItem}>
        <Box className={isInProgress ? classes.inProgress : classes.unitHeader}>
          <Box className={classes.unitName}>
            {isCompleted && <TaskAltIcon className={classes.completedIcon} />}
            {isInProgress && <PendingIcon className={classes.inProgressIcon} />}
            <Typography variant="body2" className={[classes.unitTitle, isNotStarted ? classes.notStarted : ''].join(' ')}>
              {unit.name}
            </Typography>
          </Box>
          {unit.contentProgress.total > 0 && (
            <Typography variant="body2" className={classes.unitProgress}>
              <FormattedMessage
                id="unitTopics"
                values={{
                  total: unit.contentProgress.total,
                }}
              />
            </Typography>
          )}
        </Box>
        {isInProgress && (
          <IconButton
            className={classes.unitLaunchButton}
            aria-label="Go to unit"
            onClick={(event) => {
              event.stopPropagation();
              launchModule();
            }}
            tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
            tracking-id={`unit-launch-${unit.id}`}
          >
            <ArrowCircleRightSharpIcon />
          </IconButton>
        )}
      </Box>
      {!isLast && <Divider className={classes.unitDivider} />}
    </>
  );
};

ModuleUnit.propTypes = {
  classes: PropTypes.object.isRequired,
  unit: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    progress: PropTypes.string.isRequired,
    contentProgress: PropTypes.shape({
      total: PropTypes.number.isRequired,
      completed: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  isLast: PropTypes.bool.isRequired,
  launchModule: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModuleUnit);
