export default (theme) => ({
  '@global': {
    '& #main-container': {
      height: '100%',
    },
  },
  root: {
    margin: '24px 24px 24px 101px',
    padding: '24px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    height: 'calc(100dvh - 136px)',
    overflowY: 'auto',
  },
  image: {
    width: '161px',
    height: '218px',
  },
  title: {
    fontSize: 30,
    fontWeight: 800,
    marginBottom: '24px !important',
    color: theme.palette.aqua.extraDeep,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: '24px !important',
    color: theme.palette.greyScale[110],
    whiteSpace: 'pre-line',
  },
  programList: {
    marginTop: theme.spacing(2),
  },
  programOption: {
    marginBottom: theme.spacing(2),
  },
  programHeader: {
    margin: 0,
    width: '100%',
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControlLabel-label': {
      flex: 1,
    },
    '& .MuiIconButton-label': { color: theme.palette.blue.primary },
  },
  selected: {
    border: `1px solid ${theme.palette.blue.main}`,
    borderRadius: '12px',
    backgroundColor: theme.palette.blue.pale,
    '& h3': {
      fontWeight: 700,
      color: theme.palette.blue.primary,
    },
  },
  programTitle: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.greyScale[110],
  },
  programContent: {
    padding: '0 16px 24px',
  },
  programDescription: {
    fontSize: '16px !important',
    fontWeight: 400,
    lineHeight: '24px !important',
    color: theme.palette.greyScale[110],
  },
  alreadyEnrolled: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.greyScale[110],
  },
  selectButton: {
    borderRadius: 100,
    marginTop: '12px',
    padding: '12px 20px',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none',
    backgroundColor: theme.palette.blue.primary,
    color: 'white',
    '& span': {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    '&:hover': {
      backgroundColor: theme.palette.blue.primary,
    },
  },
});
