import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, withStyles } from '@material-ui/core';
import styles from './video-modal-player.styles';
import useVideoModalPlayerHook from './useVideoModalPlayerHook';
import { useIntl } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import { VideoPlayer } from '@orijinworks/frontend-commons';

/**
 * Component to display the video player in a modal.
 *
 * @component
 * @param {object} props - The component props.
 * @returns {React.Component} The video modal player component.
 */
const VideoModalPlayer = ({ classes }) => {
  const { open, showPlayer, movieDetails, adUrl, handleClose, showLanguageList, handleLanguageChange, isLanguageActive } =
    useVideoModalPlayerHook();
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle disableTypography className={classes.titleContainer}>
        <Typography data-testid="movie-name" variant="h1" className={classes.title}>
          {movieDetails.movieName}
        </Typography>
        <IconButton
          data-testid="close-dialog-btn"
          onClick={handleClose}
          className={classes.closeDialogBtn}
          aria-label={intl.formatMessage({ id: 'close' })}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showLanguageList() && (
          <Box className={classes.languagesList}>
            {movieDetails.languages.map((language, index) => (
              <Button
                data-testid={`language-${language.prefix}-btn`}
                key={language.prefix}
                color="primary"
                variant={isLanguageActive(index) ? 'contained' : 'outlined'}
                aria-label={language.prefix}
                onClick={() => {
                  handleLanguageChange(index);
                }}
              >
                {language.prefix}
              </Button>
            ))}
          </Box>
        )}
        {showPlayer && <VideoPlayer videoUrl={movieDetails.movieUrl} poster={movieDetails.thumbnail} adUrl={adUrl} />}
      </DialogContent>
    </Dialog>
  );
};

VideoModalPlayer.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoModalPlayer);
