import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@orijinworks/frontend-commons';
import styles from './program-details.styles';
import useProgramDetailsHook from './useProgramDetailsHook';
import { FormattedMessage, useIntl } from 'react-intl';
import { CertificateDialog, CertificateTile } from '../../../programs/components';
import ProgramContent from '../program-content/program-content';
import SelectNextProgram from '../select-next-program/select-next-program';
import { useDocumentTitleHook } from '../../../../shared';

const ProgramDetails = ({ classes, ...props }) => {
  useDocumentTitleHook('page.title.career-compass.program-details');
  const {
    programDetails,
    programProgress,
    showCertificate,
    hasProgress,
    getProgress,
    showCertificateDialog,
    closeCertificateDialog,
    showProgramContent,
    showPickNextProgram,
    onPickNextProgram,
    isSingleCourseProgram,
  } = useProgramDetailsHook(props);
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerContent}>
          {programDetails && (
            <>
              <img src={programDetails.thumbnailUri} alt="" className={classes.image} />
              <Box className={classes.mainContent}>
                <Chip label={intl.formatMessage({ id: 'career-compass.program-details.chip' })} className={classes.chip} />
                <Box className={classes.headerContent}>
                  <Typography variant="h2" className={classes.title}>
                    {programDetails.name}
                  </Typography>
                  <Typography variant="body1" className={classes.description}>
                    {programDetails.description}
                  </Typography>
                </Box>

                {hasProgress() && (
                  <Box marginBottom="3px">
                    <Box className={classes.progressBarText}>
                      <Typography variant="body2" className={classes.boldText}>
                        <FormattedMessage id="career-compass.program-details.certificate-progress" />
                      </Typography>
                      <Typography variant="body2" className={classes.alignRight}>
                        <FormattedMessage
                          id="career-compass.program-details.badges-earned"
                          values={{
                            earnedBadgeCount: programProgress?.earnedBadgeCount ?? 0,
                            totalBadgeCount: programProgress?.totalBadgeCount ?? 0,
                          }}
                        />
                      </Typography>
                    </Box>
                    <LinearProgress
                      id="program-progress-bar"
                      variant="determinate"
                      value={getProgress()}
                      classes={{
                        root: classes.progressBarRoot,
                        colorPrimary: classes.progressBarColorPrimary,
                        bar: classes.progressBar,
                      }}
                      aria-label="Program Progress Bar"
                    />
                  </Box>
                )}

                {programDetails?.certificateUrl && (
                  <>
                    <CertificateDialog
                      open={showCertificate}
                      onClose={closeCertificateDialog}
                      certificateUrl={programDetails?.certificateUrl}
                    />
                    <Box className={classes.certificateSection}>
                      <Box className={classes.certificateLabel}>
                        <FormattedMessage id={'career-compass.program-details.congratulations'} />
                      </Box>
                      <Box className={classes.certificate}>
                        <CertificateTile onViewCertificate={showCertificateDialog} showThumbnail={false} />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
      {showProgramContent() && (
        <ProgramContent
          isSingleCourseProgram={isSingleCourseProgram}
          program={programDetails}
          content={programDetails.learningObjects}
        />
      )}
      {showPickNextProgram() && <SelectNextProgram onContinue={onPickNextProgram} />}
    </Box>
  );
};

ProgramDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  currentStep: PropTypes.object.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  handleCurrentStepUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProgramDetails);
